import type { BannerType, PrefetchedData } from '../types';

export function getCookieName(
	bannerType: BannerType | undefined,
	effectiveDate: string | undefined,
	prefetchedData?: Pick<PrefetchedData, 'tosBannerType' | 'tosBannerEffectiveDate'>,
): string | undefined {
	if (prefetchedData) return getCookieName(prefetchedData.tosBannerType, prefetchedData.tosBannerEffectiveDate);
	if (!effectiveDate || !bannerType) return undefined;
	return `tos_banner_remaining_views_${bannerType}_${effectiveDate}`;
}
