import { useNow } from '@change-corgi/core/react/date';

import type { BannerType, PrefetchedData, TosConfig } from './types';

function addDays(date: Date, days: number): Date {
	const res = new Date(date.getTime());
	res.setDate(res.getDate() + days);
	return res;
}

function getBannerType(now: Date, config: TosConfig): BannerType | undefined {
	if (!config) return undefined;

	const effectiveDate = new Date(config.effectiveDate);
	const preEffectiveStartDate = addDays(effectiveDate, -1 * config.preEffectiveRange);
	const postEffectiveEndDate = addDays(effectiveDate, config.postEffectiveRange);

	if (now < preEffectiveStartDate || now > postEffectiveEndDate) return undefined;
	if (now < effectiveDate) return 'pre';
	return 'post';
}

export function useBannerType(
	config: TosConfig,
	prefetchedData?: Pick<PrefetchedData, 'tosBannerType'>,
): BannerType | undefined {
	const now = useNow();

	if (prefetchedData) return prefetchedData.tosBannerType;

	return getBannerType(now, config);
}

export function prefetchBannerType(config: TosConfig): BannerType | undefined {
	return getBannerType(new Date(), config);
}
